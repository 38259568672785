<template>
  <el-dialog
    :title="dialogTitle"
    :visible="true"
    :width="width"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <div v-if="allBranch">
      <p v-if="allWeek">{{ allBranchAndAllWeekText }}</p>
      <div v-else>
        <p>所有門市套用下方設定：</p>
        <div v-for="(item, idx) in displayData" :key="idx" class="flex items-start gap-[20px]">
          <div v-if="item.label">
            {{ item.label }}
          </div>
          <div class="flex flex-col">
            <div v-for="(text, textIdx) in item.content" :key="textIdx">
              {{ text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="grid gap-[20px]">
      <div v-for="(branch, idx) in eachBranchDisplayData" :key="idx" class="flex flex-col" style="gap: 8px">
        <p class="font-bold text-gray-100 text-normal">{{ branch.name }}</p> <!-- 還沒有name 先傳ID -->
        <div v-for="(item, idx) in branch.dataList" :key="idx" class="flex items-start gap-[20px]">
          <div v-if="item.label">
            {{ item.label }}
          </div>
          <div class="flex flex-col">
            <div v-for="(text, textIdx) in item.content" :key="textIdx">
              {{ text }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { toNumber, find, join, get, groupBy } from 'lodash'

export default defineComponent({
  name: 'EntryControlConfigDialog',
  props: {
    title: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    show: Boolean,
    width: {
      type: String,
      default: '440px',
    },
  },
  setup (props) {
    const dialogTitle = computed(() => `「${get(props, 'title')} 」綁定進場門市詳情`)
    const allBranch = computed(() => get(props.data, 'settings[0].allBranch'))
    const allWeek = computed(() => get(props.data, 'settings[0].allWeek'))
    const allBranchAndAllWeek = computed(() => {
      return allBranch.value && allWeek.value
    })
    const allBranchAndAllWeekText = computed(() => {
      let text = ''
      if (allBranchAndAllWeek.value) text = '所有門市週一至週日'
      const period = get(props.data, 'settings[0].period')
      if (period) {
        text += ` ${formatTime(period.startTime)} ~ ${formatTime(period.endTime)}`
      }
      return text
    })
    const formatTime = (number) => {
      const hours = Math.floor(number / 60)
      const minutes = number % 60
      const formattedHours = String(hours).padStart(2, '0')
      const formattedMinutes = String(minutes).padStart(2, '0')
      return `${formattedHours}:${formattedMinutes}`
    }
    const formatAllowResourcesPreviewText = (allowResources) => {
      if (allowResources.includes('pointCard') && allowResources.includes('punchCard')) {
        return '開放次卡與點數卡'
      }
      if (allowResources.includes('punchCard')) {
        return '開放次卡'
      }
      if (allowResources.includes('pointCard')) {
        return '開放點數卡'
      }
      return ''
    }
    const weekDays = [
      { label: '週一', value: 1 },
      { label: '週二', value: 2 },
      { label: '週三', value: 3 },
      { label: '週四', value: 4 },
      { label: '週五', value: 5 },
      { label: '週六', value: 6 },
      { label: '週日', value: 0 },
    ]
    const handleMaxDurationAndOvertimeExit = ({ setting, textLines }) => {
      const { enableMaxDuration, enableOvertimeExit, maxDuration, overtimeExitAllowRule, overtimeExitAllowMinutes } = setting
      if (enableMaxDuration) {
        textLines.push(`限定單次進場最高時數上限 ${maxDuration} 分鐘`)
      }
      if (enableOvertimeExit) {
        if (overtimeExitAllowRule === 'allowForever') textLines.push('無論超時多久均可出場')
        if (overtimeExitAllowRule === 'allowMinutes' && overtimeExitAllowMinutes) textLines.push(`允許超時 ${overtimeExitAllowMinutes} 分鐘`)
      }
    }
    const displayData = computed(() => {
      const configData = props.data
      const enable = configData.enable
      const allBranch = get(configData, 'settings[0].allBranch')

      if (allBranch) {
        if (!enable) return '所有門市不開放進場'
        const allWeek = get(configData, 'settings[0].allWeek')
        if (!allWeek) {
          const dataList = []
          const eachWeekSetting = get(configData, 'settings')

          eachWeekSetting.sort((a, b) => {
            // week === 0 排在最後
            const aWeek = toNumber(a.week)
            const bWeek = toNumber(b.week)
            if (aWeek === 0) return 1
            if (bWeek === 0) return -1
            return aWeek - bWeek
          })

          for (const weekDaySetting of weekDays) {
            const textLines = []
            const item = find(eachWeekSetting, item => toNumber(item.week) === toNumber(weekDaySetting.value))
            const weekDayLabel = weekDaySetting.label
            if (item) {
              const { period } = item
              textLines.push(`${formatTime(period.startTime)} ~ ${formatTime(period.endTime)}`)

              handleMaxDurationAndOvertimeExit({ setting: item, textLines })

              dataList.push({
                label: weekDayLabel,
                content: textLines,
              })
            } else {
              textLines.push('不開放進場')
              dataList.push({
                label: weekDayLabel,
                content: textLines,
              })
            }
          }
          return dataList
        }
      }
      return []
    })
    const eachBranchDisplayData = computed(() => {
      const configData = props.data
      const branchData = []
      const eachBranchSettings = get(configData, 'settings')
      const processedBranchIds = new Set()
      // console.log(processedBranchIds)
      // const groupedByBranchId = groupBy(eachBranchSettings, 'branchId')
      // console.log('groupedByBranchId', groupedByBranchId)
      for (const branchSetting of eachBranchSettings) {
        const allWeek = get(branchSetting, 'allWeek')
        const branchStoreId = get(branchSetting, 'branchId')
        const branchName = get(branchSetting, 'branchName')
        // console.log('branchStoreId', branchStoreId)
        if (processedBranchIds.has(branchStoreId)) {
          continue // Skip processing if a duplicate branchId is found
        }
        if (allWeek) {
          const textLines = []
          const periodSettings = get(branchSetting, 'period')
          textLines.push(`週一至週日：${formatTime(periodSettings.startTime)} ~ ${formatTime(periodSettings.endTime)}`)

          branchData.push({
            name: branchName,
            dataList: [{ content: textLines }],
          })
        } else {
          const dataList = []
          const eachWeekSetting = get(configData, 'settings')

          eachWeekSetting.sort((a, b) => {
          // week === 0 排在最後
            const aWeek = toNumber(a.week)
            const bWeek = toNumber(b.week)
            if (aWeek === 0) return 1
            if (bWeek === 0) return -1
            return aWeek - bWeek
          })

          for (const weekDaySetting of weekDays) {
            const textLines = []
            const item = find(eachWeekSetting, item => toNumber(item.week) === toNumber(weekDaySetting.value))
            const weekDayLabel = weekDaySetting.label
            if (item) {
              const { period } = item
              textLines.push(`${formatTime(period.startTime)} ~ ${formatTime(period.endTime)}`)

              handleMaxDurationAndOvertimeExit({ setting: item, textLines })

              dataList.push({
                label: weekDayLabel,
                content: textLines,
              })
            } else {
              textLines.push('不開放進場')
              dataList.push({
                label: weekDayLabel,
                content: textLines,
              })
            }
          }
          branchData.push({
            name: branchName,
            dataList,
          })
        }
        processedBranchIds.add(branchStoreId)
      }
      return branchData
    })
    return {
      dialogTitle,
      allBranch,
      allWeek,
      allBranchAndAllWeek,
      allBranchAndAllWeekText,
      displayData,
      eachBranchDisplayData,
    }
  },
})
</script>

<style scoped lang="scss"></style>
